'use strict'

const events = {
    REPORT_TRACE: {
        params: {
            src: 72,
            evid: 100
        },
        endpoint: 'trace',
        isSampled: false
    },
    USER_CODE_LOADED: {
        params: {
            src: 79,
            evid: 133
        },
        isSampled: true
    }
}

module.exports = events
