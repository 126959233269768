'use strict'

module.exports = {
    WIX_CODE_SSR: 'WIX_CODE_SSR',
    WIDGET_READY: 'widget_ready',
    WIX_CODE_INTENT: 'WIX_CODE',
    TPA_NATIVE_EVENT: 'TPA_NATIVE_EVENT',
    WIX_CODE_ANIMATE: 'WIX_CODE_ANIMATE',
    WIDGET_STATUS_CHANGE: 'widget_status_changed',
    CONSOLE_MESSAGE: 'console',
    SET_TO_STORAGE: 'setToStorage',
    STORAGE_UPDATE: 'storageWasUpdated',
    BOOTSTRAP: 'wix_code_worker_bootstrap',
    BOOTSTRAP_DONE: 'worker_bootstrap_done',
    LOAD_USER_CODE: 'wix_code_worker_load_user_code',
    LOAD_USER_GENERATED_APPS: 'wix_code_load_user_generated_apps',
    LOAD: 'wix_code_worker_load',
    INIT: 'wix_code_worker_init',
    START: 'wix_code_worker_start',
    PAGE_RENDERED: 'trigger_onRender',
    UPDATE_WIX_CODE_DATA_AFTER_LOGIN: 'update_wix_code_model_data_after_login',
    REQUEST_API: 'REQUEST_API',
    PLATFORM_PUBLIC_API_PREFIX: 'viewer_platform_public_api_',
    PERFORMANCE_METRICS_READY: 'performance_metrics_ready',
    SCRIPT_IMPORT_MESSAGE: 'script_import_message',
    STOP: 'stop',
    IFRAME_LOADED_MESSAGE_TYPE: 'wix_code_iframe_loaded',
    WORKER_IFRAME_LOADED_MESSAGE_TYPE: 'worker_iframe_loaded',
    PLATFORM_IFRAME_READY_INTENT: 'PLATFORM_IFRAME',
    WIX_CODE_RESPONSE: 'WIX_CODE_RESPONSE',
    IFRAME_SET_BOOTSTRAP_PARAMETERS_MESSAGE_TYPE: 'wix_code_iframe_set_bootstrap_parameters',
    IFRAME_INITIALIZED_MESSAGE_TYPE: 'wix_code_iframe_initialized',
    INVOKE_WORKER_SUBSCRIBERS: 'invoke_worker_subscribers',
    WIX_CODE_SITE_API_INTENT: 'WIX_CODE_SITE_API',
    UPDATE_WORKER_BI_DATA: 'wix_code_worker_bi_data_update',
    UPDATE_SITE_MEMBER: 'update_site_member',
    UPDATE_QUEUE_INTENT: 'UPDATE_QUEUE',
    RESET_EDITOR_WORKER_QUEUE_TO_WAIT_FOR_LOAD: 'reset_editor_worker_queue_to_wait_for_load'
}
