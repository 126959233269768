'use strict'

const platformServices = require('./app-services')
const _ = require('lodash')
const constants = require('./constants/constants')
const raven = require('raven-js')

const RENDERING_ENVS_TO_REPORT_FROM = ['browser']
const DEV_QUERY_PARAMS = [
    'ReactSource',
    'EditorSource',
    'experiments',
    'WixCodeRuntimeSource',
    'debug',
    'debugViewer'
]

const INTEGRATION_QUERY_PARAMS = [
    'isWixCodeIntegration'
]

const isExceptionInWorker = exceptionFrames => {
    const exceptionOriginFilename = _.get(_.last(exceptionFrames), 'filename')
    return _.startsWith(exceptionOriginFilename, './src') ||
    _.startsWith(exceptionOriginFilename, 'webpack/bootstrap') ||
    _.endsWith(exceptionOriginFilename, 'wixcode-worker.js')
}

const createShouldSendCallback = (validationResults = []) => {
    const {
        shouldSendReportByFilename,
        shouldSendReportByOperatingSystem
    } = platformServices.monitoring.getFilterUtils()

    return data => _.every(validationResults.concat([
        shouldSendReportByFilename(data, isExceptionInWorker),
        shouldSendReportByOperatingSystem()
    ]))
}

const createSessionShouldSendCallback = (renderingEnv, queryParams) => {
    const filters = platformServices.monitoring.getFilterUtils()
    const shouldSendByRenderingEnv = filters.shouldSendReportByRenderingEnv(renderingEnv, RENDERING_ENVS_TO_REPORT_FROM)

    const devAndIntegrationQueryParams = DEV_QUERY_PARAMS.concat(INTEGRATION_QUERY_PARAMS)
    const shouldSendByUrls = filters.shouldSendReportByUrls(queryParams, devAndIntegrationQueryParams)

    return createShouldSendCallback([shouldSendByRenderingEnv, shouldSendByUrls])
}

module.exports = {
    initRavenWorkerServices: platformServices.monitoring.initSession.bind(this, raven, constants.WORKER_SENTRY_DSN, {
        shouldSendCallback: createShouldSendCallback()
    }),
    setSessionDataCallback: platformServices.monitoring.setSessionDataCallback,
    setSessionShouldSendCallback: platformServices.monitoring.setSessionShouldSendCallback,
    createSessionShouldSendCallback,
    raven
}
