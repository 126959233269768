'use strict'
const {fedops} = require('./utils/loggingUtils')
const _ = require('lodash')


module.exports = {
    reportPlatformInitFedops(appsToInitControllers, loadedApps) {
        if (_.isEmpty(appsToInitControllers)) {
            const {reportInteractionStarted} = fedops.getInteractionReportFunctions({name: 'empty_apps_init_phase'})
            reportInteractionStarted()
        }
        if (loadedApps.length > appsToInitControllers.length) {
            const {reportInteractionStarted} = fedops.getInteractionReportFunctions({name: 'more_apps_init_phase'})
            reportInteractionStarted()
        }
    }
}
