'use strict'

const _ = require('lodash')
let raven = null // eslint-disable-line santa/no-module-state

const messages = require('./messages')
const filterUtils = require('./filterUtils')

let sessionDataCallback = _.noop // eslint-disable-line santa/no-module-state

function createDataCallback(customerDataCallback = _.noop) {
    return data => {
        const cloneData = _.cloneDeep(data)
        return _.merge(data,
            sessionDataCallback(cloneData),
            customerDataCallback(cloneData))
    }
}

function initSession(Raven, targetAddress, {shouldSendCallback} = {}) {
    if (raven !== null) {
        console.warn(messages.SESSION_HAS_BEEN_ALREADY_INITIALIZED)
        return
    }

    if (_.isEmpty(Raven) || _.isEmpty(targetAddress)) {
        throw new Error(messages.INVALID_SESSION_ARGUMENTS)
    }

    raven = Raven
    const ravenOptions = {
        ignoreUrls: [/(localhost)/, /(127.0.0.1)/],
        dataCallback: createDataCallback(),
        captureUnhandledRejections: false
    }

    if (_.isFunction(shouldSendCallback)) {
        _.assign(ravenOptions, {shouldSendCallback})
    }

    raven.config(targetAddress, ravenOptions).install()
}

function createMonitor(targetAddress, dataCallback = _.noop) {
    if (raven === null) {
        throw new Error(messages.SESSION_IS_NOT_INITIALIZED)
    }

    if (_.isEmpty(targetAddress) || !_.isFunction(dataCallback)) {
        throw new Error(messages.INVALID_CREATE_MONITOR_ARGUMENTS)
    }

    const monitor = new raven.Client()
    monitor.config(targetAddress, {
        dataCallback: createDataCallback(dataCallback),
        shouldSendCallback: filterUtils.shouldSendReportByOperatingSystem
    })
    return monitor
}

function setSessionDataCallback(cb) {
    if (!_.isFunction(cb)) {
        throw new Error(messages.INVALID_SESSION_DATA_CALLBACK)
    }
    sessionDataCallback = cb
}

function setSessionShouldSendCallback(cb) {
    if (raven === null) {
        throw new Error(messages.SESSION_IS_NOT_INITIALIZED)
    }

    if (!_.isFunction(cb)) {
        throw new Error(messages.INVALID_SHOULD_SEND_CALLBACK)
    }

    raven.setShouldSendCallback(cb)
}

function getSessionData() {
    return _.cloneDeep(_.assign({}, sessionDataCallback()))
}

function getFilterUtils() {
    return filterUtils
}

module.exports = {
    getSessionData,
    initSession,
    createMonitor,
    setSessionDataCallback,
    setSessionShouldSendCallback,
    getFilterUtils
}
