'use strict'

const uaParser = require('ua-parser-js')
const _ = require('lodash')

const SUPPORTED_MOBILE_OS = {Android: 7.0, iOS: 11}

const shouldSendReportByUrls = (queryParameters, excludedQueryParams) =>
    _.every(queryParameters, param => !_.includes(excludedQueryParams, param))

const shouldSendReportByFilename = (data, filenamesToReportCallback) => {
    if (!_.isFunction(filenamesToReportCallback)) {
        return false
    }

    const exceptionFrames = _.get(data, ['exception', 'values', 0, 'stacktrace', 'frames'], [])
    const filteredFrames = exceptionFrames.filter(frame => frame && _.isString(frame.filename))
    return filenamesToReportCallback(filteredFrames)
}

const shouldSendReportByRenderingEnv = (currentEnv, envsToReportFrom) => _.includes(envsToReportFrom, currentEnv)

const shouldSendReportByOperatingSystem = () => {
    if (!self.navigator) {
        return true
    }

    const {os} = uaParser(navigator.userAgent)
    const earliestSupportedVersion = _.get(SUPPORTED_MOBILE_OS, os.name)
    return earliestSupportedVersion ? earliestSupportedVersion <= parseFloat(os.version) : true
}

const filters = {
    shouldSendReportByUrls,
    shouldSendReportByFilename,
    shouldSendReportByRenderingEnv,
    shouldSendReportByOperatingSystem
}

module.exports = filters
