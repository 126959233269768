'use strict'
const _ = require('lodash')

module.exports = class AppsStore {
    constructor() {
        this._apps = {}
        this._appsByApplicationIds = {}
    }
    get(id) {
        return this._apps[id] || this._apps[this._appsByApplicationIds[id]]
    }
    set(id, value) {
        this._apps[id] = value
        return this
    }
    update(id, path, value) {
        _.set(this._apps[id], path, value)
        return this
    }
    mapInnerId(innerId, id) {
        this._appsByApplicationIds[innerId] = id
    }
    getAll() {
        return Object.keys(this._apps).map(k => this._apps[k])
    }
    updateControllerScriptMap(appId, controllerId, controllerModule) {
        this._apps[appId].controllerScriptMap[controllerId] = controllerModule
    }
}
