'use strict'
const _ = require('lodash')

function importScriptsAsNpmModule(url, {
    onSuccess = _.noop,
    onError = _.noop,
    scriptHandler = self
} = {}) {
    const module = self.module = {}
    self.module.exports = {}
    try {
        scriptHandler.importScripts(url)
        onSuccess()
    } catch (err) {
        onError(err)
    }
    delete self.module
    if (module.exports && module.exports.__esModule) {
        return module.exports.default
    }
    return module.exports
}

module.exports = {
    importScriptsAsNpmModule
}