'use strict'
const _ = require('lodash')
const urlUtils = require('../utils/urlUtils')

function setElementoryArguments(args) {
    if (!args.baseUrl || typeof args.baseUrl !== 'string') {
        throw new Error('Load message data must include baseUrl of type string')
    }
    if (!args.queryParameters || typeof args.queryParameters !== 'string') {
        throw new Error('Load message data must include queryParameters of type string')
    }

    if (!self.elementorySupport) {
        self.elementorySupport = {}
    }

    self.elementorySupport.baseUrl = args.baseUrl
    self.elementorySupport.queryParameters = args.queryParameters
    self.elementorySupport.options = _.assign({}, self.elementorySupport.options, args.options)
}

function getElementoryArguments(widgets, baseUrl, viewMode) {
    const wixCodeAppConfig = getAppConfig(widgets)
    if (wixCodeAppConfig) {
        return {
            baseUrl,
            queryParameters: getQueryParameters(wixCodeAppConfig, viewMode)
        }
    }
    return undefined
}

function getAppConfig(widgets) {
    const widgetWithAppConfigIndex = _.findIndex(widgets, widgetDef => widgetDef.appConfig)

    if (widgetWithAppConfigIndex >= 0) {
        return widgets[widgetWithAppConfigIndex].appConfig
    }

    return undefined
}

function getQueryParameters({instance, gridAppId}, viewMode) {
    const parameters = {
        instance,
        gridAppId,
        viewMode
    }

    return urlUtils.getParametersAsString(parameters)
}


module.exports = {
    setElementoryArguments,
    getElementoryArguments
}
